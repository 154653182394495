import Swal from "sweetalert2";

export const toastFire = (
  text,
  icon = "success",
  position = "bottom-start"
) => {
  Swal.fire({
    title: text,
    icon,
    timer: 2000,
    showCancelButton: false,
    showConfirmButton: false,
    toast: true,
    position,
  });
};

export const errorFire = (title, text = null, timer = 2000) => {
  Swal.fire({
    title,
    text,
    icon: "error",
    timer,
    showCancelButton: false,
    showConfirmButton: false,
  });
};
